















































































































































































































import { Component, Inject, InjectReactive, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscPageHeader from "@/components/FscPageHeader.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import UploadButton from "@/components/Button/UploadButton.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import Actions from "@/components/Actions.vue";
import CopyButton from "@/components/Button/CopyButton.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import FscCard from "@/components/Card/FscCard.vue";
import MailButton from "@/components/Button/MailButton.vue";
import InfoButton from "@/components/Button/InfoButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import Table from "@/components/Table.vue";
import BookButton from "@/components/Button/BookButton.vue";
import { BModal } from "bootstrap-vue/src/components/modal";
import SendMessage from "@/views/Exam/TheoryExam/Participants/Edit/SendMessage.vue";
import DeleteModal from "@/components/Modal/DeleteModal.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";
import ExamMixin from "@/mixins/ExamMixin";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import TheoryExamProtocolPreview from "@/views/Exam/TheoryExam/Participants/Edit/TheoryExamProtocolPreview.vue";
import PriceMixin from "@/mixins/PriceMixin";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import StudentEducationInfoPopover from "@/components/StudentEducationInfoPopover.vue";
import Info from "@/views/Exam/TheoryExam/Participants/Info.vue";
import ExamDocumentMixin from "@/mixins/ExamDocumentMixin";
import ExamDocumentModal from "@/components/Exam/ExamDocumentModal.vue";
import { ExamDocumentTypeEnum } from "@/enums/ExamDocumentTypeEnum";
import { checkFileSizeBeforeUpload } from "@/helper/files";
import { UploadConfiguration } from "@/enums/UploadConfiguration";
import _ from "lodash";
import { formatStudentName } from "@/utils/NameUtil";
import WalletButton from "@/components/Button/WalletButton.vue";
import ActionButton from "@/components/Button/ActionButton.vue";

const ExamStatusModule = namespace("exam-status");

@Component({
  methods: { formatStudentName },
  components: {
    ExamDocumentModal,
    Info,
    FilterAndSearch,
    Avatar,
    SendMessage,
    BookButton,
    SaveButton,
    AbortButton,
    InfoButton,
    MailButton,
    FscCard,
    FilterButton,
    ArchiveButton,
    CopyButton,
    Actions,
    PrintButton,
    FileCsvButton,
    UploadButton,
    RemoveButton,
    VerticalDivider,
    EditButton,
    CreateButton,
    FscPageHeader,
    Table,
    DeleteModal,
    FscModal,
    TheoryExamProtocolPreview,
    FscSimpleCard,
    StudentEducationInfoPopover,
    WalletButton,
    ActionButton,
  },
})
export default class TheoryExamParticipantsList extends mixins(ModalMixin, ExamMixin, PriceMixin, ExamDocumentMixin) {
  public name = "TheoryExamParticipantsList";

  private protocolPopover = "protocolPopoverTheoryExam";

  @Prop()
  private examId!: any;

  @Prop()
  public exam: any;

  @Prop({ default: () => false, type: Boolean })
  public loading!: boolean;

  // Theory exam status
  @Inject("updateTheoryExamStatus")
  private updateExamStatusAction!: (data: any) => Promise<void>;

  @InjectReactive("updateTheoryExamStatusSuccess")
  private examStatusSuccess!: boolean;

  @InjectReactive("updateTheoryExamStatusLoading")
  private examStatusLoading!: boolean;

  // Book theory exam
  @Inject("bookTheoryExam")
  private bookAction!: (theoryExamId: number) => Promise<void>;

  @InjectReactive("bookTheoryExamSuccess")
  private bookingSuccess!: boolean;

  @InjectReactive("bookTheoryExamLoading")
  private bookingLoading!: boolean;

  //remove participants
  @Inject("deleteParticipantsTheoryExam")
  private removeStudent!: (theoryExamId: number) => Promise<void>;

  @InjectReactive("deleteParticipantsTheoryExamSuccess")
  private removeStudentSuccess: any;

  // todo: replace with mixin request
  @ExamStatusModule.Action("findAll")
  private examStatusFindAll: any;

  @ExamStatusModule.Getter("getDataList")
  private examStatuses: any;

  // upload practical exam protocol
  @Inject("uploadProtocolTheoryExam")
  private uploadProtocolAction!: (data: any) => Promise<void>;

  @InjectReactive("uploadProtocolTheoryExamLoading")
  private isUploadLoading!: boolean;

  @InjectReactive("uploadProtocolTheoryExamSuccess")
  private uploadSuccess!: boolean;

  // Participant Info Theory Exam
  @Inject("participantInfoTheoryExam")
  private getExamTheoryPracticeInfoAction!: (studentEducationId: number) => Promise<void>;

  @InjectReactive("participantInfoTheoryExamData")
  private getEducationStatusInfo: any;

  // Book theory exam product
  @Inject("bookTheoryExamProduct")
  private bookTheoryProduct!: (studentEducationId: number, studentTheoryExamId: number) => Promise<void>;

  @InjectReactive("bookTheoryExamProductSuccess")
  private bookTheoryProductSuccess!: boolean;

  @InjectReactive("bookTheoryExamProductError")
  private bookTheoryProductError!: string | null;

  @InjectReactive("bookTheoryExamProductLoading")
  private bookTheoryExamProductLoading!: boolean;

  @Inject("fetchTheoryExam")
  protected fetchTheoryExam!: any;

  @InjectReactive("archiveTheoryExamLoading")
  private archiveTheoryExamLoading!: boolean;

  public selectedRow: any = null;
  public currentPopoverId: any = 0;
  public studentTheoryExams: Array<any> = [];

  private studentFields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
    {
      key: "participation",
      label: this.$t("general.participation"),
      sortable: false,
    },
    {
      key: "licenseClass",
      label: this.$t("calendar.class"),
      sortable: false,
    },
    {
      key: "readyForTheoryExam",
      label: "§",
      sortable: false,
      class: "text-center",
    },
    {
      key: "saldo",
      label: this.$t("students.balance"),
      sortable: false,
    },
    {
      key: "learnStatus",
      label: "drive.buzz",
      sortable: false,
    },
    {
      key: "examStatus",
      label: this.$t("general.status"),
      sortable: false,
    },
    {
      key: "booking",
      label: "",
      sortable: false,
    },
    {
      key: "protocol",
      label: this.$t("general.protocol"),
      sortable: false,
    },
    {
      key: "protocolStudentDocumentId",
      label: "",
    },
  ];

  private info = {
    studentEducationId: 121,
    requirementsToFulfillmentMap: [
      { name: "Nachtfahrten", status: false },
      { name: "Specific theory", status: true },
      { name: "Autobahnfahrten", status: false },
      { name: "Basic theory", status: false },
      { name: "Überlandfahrten", status: false },
    ],
  };

  public studentDocumentId: any = null;
  public mappedStudentsList: Array<any> = [];
  public studentFieldsCopy: any = [];
  public reduceColumns = false;
  public studentEducationId: any = null;
  public studentTheoryExamId: any = null;

  protected bookThExamModalId = "book-th-exam-modal";

  public openEmailForm() {
    this.studentTheoryExams = this.exam.studentTheoryExams;
    this.mappedStudentsList = this.exam.studentTheoryExams.map((item: any) => {
      return { ...item.student };
    });
    this.reduceColumns = !this.reduceColumns;
    if (this.reduceColumns) {
      this.studentFields = this.studentFields.slice(0, 2);
    } else {
      this.studentFields = this.studentFieldsCopy;
    }
    this.$emit("reduce-columns", this.reduceColumns);
    this.$emit("set-recipients", { allStudents: this.mappedStudentsList });
  }

  public get isShownPopover(): any {
    return (id: any) => this.currentPopoverId === id || false;
  }

  public mounted(): void {
    this.studentFieldsCopy = _.cloneDeep(this.studentFields);
    this.examStatusFindAll({ resource: "exams-statuses" });

    this.$root.$on("form-email-close", () => {
      this.reduceColumns = !this.reduceColumns;
      if (this.reduceColumns) {
        this.studentFields = this.studentFields.slice(0, 2);
      } else {
        this.studentFields = this.studentFieldsCopy;
      }
      this.$emit("reduce-columns", this.reduceColumns);
    });
  }

  private showBookExamQuestion(): void {
    if (!this.exam.studentTheoryExams || this.exam.studentTheoryExams.length === 0) {
      return;
    }
    this.$bvModal.show(this.bookThExamModalId);
  }

  public bookExamCancel() {
    this.$bvModal.hide(this.bookThExamModalId);
  }

  private hideSendMessage(): void {
    if (this.$refs["send-message"]) {
      (this.$refs["send-message"] as BModal).hide();
    }
  }

  private async bookExam(): Promise<void> {
    if (!this.exam.studentTheoryExams || this.exam.studentTheoryExams.length === 0) {
      return;
    }

    await this.bookAction(this.exam.id);
    if (this.bookingSuccess) {
      this.$toasted.success(String(this.$t("messages.booking_success")));
      this.fetchTheoryExam(this.examId);
    }
  }

  public onUpload(studentTheoryExamId: number): void {
    const fileInput = this.$refs["protocol_" + studentTheoryExamId];
    if (fileInput) {
      (fileInput as HTMLFormElement).click();
    }
  }

  @Watch("exam", { deep: true, immediate: true })
  public onExamChange(currentExam: any) {
    this.mappedStudentsList =
      currentExam?.studentTheoryExams?.map((item: any) => {
        return { ...item.student };
      }) || [];
    this.reduceColumns = false;
    this.$emit("reduce-columns", false);
    this.$nextTick(() => {
      this.studentFields = this.studentFieldsCopy;
      this.$emit("set-recipients", { allStudents: this.mappedStudentsList });
    });
  }

  public async onFileChange(studentTheoryExamId: number): Promise<void> {
    const fileInput = this.$refs["protocol_" + studentTheoryExamId];
    if (!fileInput) {
      return;
    }
    const file = (fileInput as HTMLFormElement).files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("studentTheoryExamId", studentTheoryExamId.toString());
      const fileSizeInvalid = !checkFileSizeBeforeUpload(file, UploadConfiguration.SIZE_NORMAL);
      if (fileSizeInvalid) {
        this.$toasted.error(String(this.$t("configuration.file_size_exceed_message", { size: " 2MB" })));
        return;
      }

      await this.uploadProtocolAction(formData);

      if (this.uploadSuccess) {
        this.$toasted.success(String(this.$t("messages.upload_success")));
        this.fetchTheoryExam(this.examId);
      }
    }
  }

  private async onSubmit(): Promise<void> {
    if (!this.exam.studentTheoryExams) return;

    const studentTheoryExamFormDTOs: Array<any> = [];
    for (const studentExam of this.exam.studentTheoryExams) {
      studentTheoryExamFormDTOs.push({
        studentId: studentExam.student.id,
        licenseClass: studentExam.licenseClass,
        time: studentExam.time,
        examStatusId: studentExam.examStatus ? studentExam.examStatus.id : 1,
        instructorId: null,
      });
    }

    await this.updateExamStatusAction({
      theoryExamId: this.exam.id,
      studentTheoryExamFormDTOs: studentTheoryExamFormDTOs,
    });

    if (this.examStatusSuccess) {
      this.$toasted.success(String(this.$t("messages.save_success")));
    }
  }

  public onRowClicked(ctx: any): void {
    this.selectedRow = ctx;
  }

  private showDeleteModal(): void {
    if (!this.selectedRow) {
      return;
    }
    this.showModal("delete-modal");
  }

  private async onDelete(): Promise<void> {
    await this.removeStudent(this.selectedRow?.id);
    if (this.removeStudentSuccess) {
      this.$emit("on-student-remove");
    }
  }

  private onClose(): void {
    this.$emit("on-close");
  }

  public onPopoverClose() {
    this.currentPopoverId = 0;
  }

  private async getEducationInfo({ id, studentEducationId }: any) {
    this.currentPopoverId = null;
    await this.getExamTheoryPracticeInfoAction(studentEducationId);
    this.info = this.getEducationStatusInfo;
    this.currentPopoverId = id;
  }

  protected onEdit(): void {
    this.reduceColumns = false;
    this.$emit("reduce-columns", false);
    this.$emit("on-edit");
  }

  protected get isBooked(): boolean {
    return this.exam.booked;
  }

  @InjectReactive("filterTheoryExam")
  public filterTheoryExam!: string;

  public protocolDocumentPreview(documentId: any) {
    if (documentId) {
      this.studentDocumentId = documentId;
      this.$bvModal.show(this.protocolPopover);
    }
    return;
  }

  protected onPrintTheoryDocument() {
    if (this.examId) {
      this.$bvModal.show(this.modalId);
    }
  }

  private get documentTypeId() {
    return ExamDocumentTypeEnum.THEORY;
  }

  public onClickBooking(user: any) {
    this.studentEducationId = user.studentEducationId ? user.studentEducationId : null;
    this.studentTheoryExamId = user.id ? user.id : null;
    this.$bvModal.show("product-book-modal");
  }

  public examStatusType(user: any) {
    if (user.examStatus && user.examStatus.id == 4) {
      return true;
    }
    return false;
  }

  public async productBookSubmit(): Promise<any> {
    if (this.studentEducationId && this.studentTheoryExamId) {
      await this.bookTheoryProduct(this.studentEducationId, this.studentTheoryExamId);
      if (this.bookTheoryProductSuccess) {
        this.$toasted.success("Teilgrundbetrag berechnet!");
        this.fetchTheoryExam(this.examId);
      }
    }
  }

  public async openBroadcastForm() {
    if (!this.exam) return;
    const theoryExamId = this.exam.id;
    await this.$router.push({ name: "Broadcast", query: { newForm: "true" } });
    this.$root.$emit("broadcast-selection-change", { formIsNew: true, entryId: theoryExamId, type: "theoryExam" });
  }

  private get isLoading() {
    return this.loading || this.bookingLoading || this.examStatusLoading || this.bookTheoryExamProductLoading || this.archiveTheoryExamLoading;
  }

  private get examStudentTheoryExams() {
    return this.exam?.studentTheoryExams || [];
  }
}
